import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "modal"]

  connect() {
    // Show the modal initially on page load
    this.open()
  }

  open() {
    this.overlayTarget.classList.remove("hidden")
    this.modalTarget.classList.remove("hidden")
  }

  close() {
    console.log('hello')
    this.overlayTarget.classList.add("hidden")
    this.modalTarget.classList.add("hidden")
  }

  submit(event) {
    event.preventDefault()
    const form = event.target

    // Form submission logic here
    fetch(form.action, {

      method: form.method,
      body: new FormData(form),
      headers: { "X-CSRF-Token": document.querySelector("[name='csrf-token']").content }
    }).then(response => {
      console.log(response);
      if (response.ok) {
        this.close()
      } else {
        alert("There was an error submitting the form.")
      }
    })
  }
}
