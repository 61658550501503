// app/javascript/controllers/menu_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  toggle() {
    console.log("hello")
    this.menuTargets.forEach(menu => menu.classList.toggle("hidden"))
  }
}
